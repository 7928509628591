<template>
    <div class="col-12 col-xl-7">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <h4>{{ $t('message.pendingOutboundNotifications') }}</h4>
                </v-col>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="refresh()"><v-icon>refresh</v-icon></v-btn>
                    <v-btn class="ml-3 info lighten-2 text--darken-1 black--text" @click="markAsProcessed()">{{ $t('message.markAsProcessed') }}</v-btn>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.notifications"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="allPendingOutboundNotifications"
            :items-per-page="20"
            :options.sync="tableOptions"
            :sort-by="'OperationNotification.senddate'"
            :search="searchTerm"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="transfersTable"
            item-key="OperationNotification.id"
            multi-sort
            show-select
            v-model="selectedNotifications"
        >
            <template v-slot:item.OperationNotification.update_type="{ item }">
                <span class="text-no-wrap">{{ capitalize(item.OperationNotification.update_type) }}</span>
            </template>
            <template v-slot:item.OperationNotification.senddate="{ item }">
                <span class="font-weight-bold text-no-wrap">{{ item.OperationNotification.senddate }}</span>
            </template>
            <template v-slot:item.OperationNotification.recipients="{ item }">
                <template v-for="recipient in item.OperationNotification.recipients">
                    <div class="text-no-wrap" v-if="recipient == 'sales_contact'">{{ $t('message.salesColleague') }}</div>
                    <div class="text-no-wrap" v-else>{{ capitalize(recipient) }}</div>
                </template>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {capitalize} from "Helpers/helpers";
import {api} from "Api";

export default {
    name: "PendingOutboundNotifications",
    data() {
        return {
            allPendingOutboundNotifications: [],
            loading: {
                filterResults: false,
                notifications: false
            },
            searchTerm: null,
            selectedNotifications: [],
            tableOptions: {
                page: 1
            },
        }
    },
    computed: {
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.sendDateTime'),
                    value: 'OperationNotification.senddate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.buyer'),
                    value: 'OperationNotification.buyer',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.contract'),
                    value: 'OperationNotification.contract',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.status'),
                    value: 'OperationNotification.status',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.module'),
                    value: 'OperationNotification.update_type',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.recipients'),
                    value: 'OperationNotification.recipients',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 7,
                    text: this.$t('message.message'),
                    value: 'OperationNotification.updated_msg',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 8,
                    text: this.$t('message.initiator'),
                    value: 'OperationNotification.created_by',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        capitalize,
        filterResults() {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function () {
                _this.loading.filterResults = false
            }, 700)
        },
        getAllPendingOutboundNotifications() {
            this.loading.notifications = true
            api
                .get('/admin/operation-notifications', {
                    params: {
                        conditions: [
                            {
                                field: 'operation_notifications.update_type',
                                value: 'shipment'
                            },
                            {
                                field: 'operation_notifications.processed',
                                value: null
                            }
                        ]
                    }
                })
                .then((response) => {
                    this.allPendingOutboundNotifications = response.data.data
                    this.loading.notifications = false
                })
                .catch(() => {
                    this.loading.notifications = false
                })
        },
        async markAsProcessed() {
            if(this.selectedNotifications.length > 0) {
                if (await this.$root.$confirm(this.$t('message.markAsProcessed') + ' ', this.$t('message.confirmations.continueMarkAsProcessedAction'), {color: 'orange'})) {
                    let notifications = []
                    this.selectedNotifications.forEach(t => {
                        notifications.push(t.OperationNotification.id)
                    })
                    api
                        .put('/admin/operation-notifications/mark-as-processed',{
                            notifications: notifications
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                this.$toast.success(this.$t('message.successes.notificationsMarkProcessed'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.getAllPendingOutboundNotifications()
                            } else {
                                this.$toast.error(this.$t('message.errors.notificationsNotMarkProcessed'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.notificationsNotMarkProcessed'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            } else {
                this.$toast.error(this.$t('message.errors.noNotificationsSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        refresh() {
            this.searchTerm = null
            this.getAllPendingOutboundNotifications()
        }
    },
    created() {
        if(this.allPendingOutboundNotifications.length == 0) this.getAllPendingOutboundNotifications()
    },
    mounted () {
        this.$title =  this.$t('message.titles.pendingOutboundNotifications')
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 250px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}

.v-data-table th:first-child {
    background-color: #c5e1a5 !important;
    border-color: #c5e1a5 !important;
}
.v-data-table td:first-child {
    padding-left: 3px !important;
}
.v-data-table .v-data-table-header th {
    white-space: nowrap;
}
</style>