var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-xl-7"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('message.pendingOutboundNotifications')))])]),_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-btn',{staticClass:"ml-3 info lighten-2 text--darken-1 black--text",on:{"click":function($event){return _vm.markAsProcessed()}}},[_vm._v(_vm._s(_vm.$t('message.markAsProcessed')))])],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.notifications,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
            itemsPerPageOptions: [20,50,-1],
            showCurrentPage: true,
            showFirstLastPage: true
        },"headers":_vm.headers,"items":_vm.allPendingOutboundNotifications,"items-per-page":20,"options":_vm.tableOptions,"sort-by":'OperationNotification.senddate',"search":_vm.searchTerm,"calculate-widths":"","dense":"","fixed-header":"","id":"transfersTable","item-key":"OperationNotification.id","multi-sort":"","show-select":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.OperationNotification.update_type",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.capitalize(item.OperationNotification.update_type)))])]}},{key:"item.OperationNotification.senddate",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text-no-wrap"},[_vm._v(_vm._s(item.OperationNotification.senddate))])]}},{key:"item.OperationNotification.recipients",fn:function(ref){
        var item = ref.item;
return [_vm._l((item.OperationNotification.recipients),function(recipient){return [(recipient == 'sales_contact')?_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('message.salesColleague')))]):_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.capitalize(recipient)))])]})]}}]),model:{value:(_vm.selectedNotifications),callback:function ($$v) {_vm.selectedNotifications=$$v},expression:"selectedNotifications"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }